<template>
  <BCard class="p-0">
    <div class="flex flex-row items-center justify-between">
      <div class="flex">
        <BButton
          variant="primary"
          size="sm"
          class="mr-1 rounded-lg p-0"
          @click="$router.go(-1)"
        >
          <feather-icon
            size="2x"
            icon="ChevronLeftIcon"
          />
        </BButton>
        <div class="text-black font-semibold m-0 text-2xl">
          Kartu Debit
        </div>
      </div>
      <BButton
        id="action"
        ref="action"
        size="sm"
        variant="outline-secondary"
      >
        <img
          src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/menu.svg"
          alt="Komerce"
          width="15"
        >
      </BButton>
      <BPopover
        target="action"
        triggers="click"
        placement="bottomleft"
      >
        <div
          v-for="act in action"
          :key="act.id"
        >
          <div
            class="text-black cursor-pointer flex gap-2"
            @click="
              act.onClick()
              $root.$emit('bv::hide::popover', 'action')
            "
          >
            <img
              :src="act.icon"
              alt="Komerce"
              width="20"
            >
            <span>{{ act.label }}</span>
          </div>
          <hr class="mt-[5px] mb-[5px]">
        </div>
      </BPopover>
    </div>
    <BRow class="justify-center">
      <CCDetail
        v-for="card in cards"
        :key="card.id"
        :card-label="card.label"
        :card-balance="card.balance"
        :card-number="!showReveal ? card.card_number : reveal.cardNumber"
        :cvv="!showReveal ? card.cvv : reveal.cvv"
        :exp-date="!showReveal ? card.exp_date : reveal.expDate"
        :copy-card-number="copyCardNumber"
      />
    </BRow>
    <BRow class="justify-center">
      <BCol
        cols="12"
        sm="7"
        md="6"
        lg="5"
        xl="4"
        class="text-center"
      >
        <BButton
          :variant="showReveal || loading.file ? 'secondary' : 'primary'"
          class="flex flex-row items-center justify-center m-auto"
          :disabled="showReveal || loading.file ? true : false"
          @click="checkReveal('reveal')"
        >
          <BSpinner
            v-if="loading.reveal || loading.file"
            variant="light"
            small
          />
          <BIcon :icon="showReveal ? 'eye-slash' : 'eye'" />
          <span class="ml-1">Cek Data Kartu <span v-if="showReveal">({{ timerCount }})</span></span>
        </BButton>
      </BCol>
    </BRow>
    <BRow class="pl-[15px] pr-[15px] items-center justify-between mt-1">
      <div class="text-black font-semibold m-0 text-2xl">
        Mutasi
      </div>
      <div class="flex items-center flex-row">
        <div class="ml-1">
          <BButton
            id="filter"
            size="sm"
            variant="primary"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-search.svg"
              alt="Komerce"
              width="20"
            >
          </BButton>
          <BPopover
            target="filter"
            triggers="click"
            placement="bottomleft"
          >
            <label>Tanggal</label>
            <BRow>
              <BCol md="6">
                <FlatPickr
                  v-model="startDate"
                  class="form-control"
                  placeholder="Mulai Dari"
                  :config="{ mode: 'single', altInput: true, altFormat: 'j/n/Y', dateFormat: 'Y-m-d',}"
                  @input="setStartDate"
                />
              </BCol>
              <BCol md="6">
                <FlatPickr
                  v-model="endDate"
                  class="form-control"
                  placeholder="Sampai Dengan"
                  :config="{ mode: 'single', altInput: true, altFormat: 'j/n/Y', dateFormat: 'Y-m-d', minDate: startDate}"
                  @input="setEndDate"
                />
              </BCol>
            </BRow>
            <label class="mt-1">Jenis Transaksi</label>
            <VSelect
              v-model="transactionType"
              :options="optionsTransactionType"
              :reduce="(option) => option.value"
              label="name"
              @input="setTransactionType"
            >
              <span
                slot="no-options"
                @click="$refs.select.open = false"
              />
            </VSelect>

            <BRow class="mx-auto mt-2">
              <BButton
                variant="outline-primary"
                class="mr-1"
                @click.prevent="resetFilter()"
              >
                Reset
              </BButton>
              <BButton
                variant="primary"
                @click.prevent="getMutation($route.params.id)"
              >
                Terapkan
              </BButton>
            </BRow>
          </BPopover>
        </div>
      </div>
    </BRow>
    <Mutation
      :scroll="'scroll'"
      :loading="loading.mutation"
      :items="listMutation"
      :fields="fields"
      :status-class="statusClass"
      :nominal-class="nominalClass"
    />

    <BModal
      id="modal-confirm-pin"
      ref="modal-confirm-pin"
      no-stacking
      centered
      hide-footer
      hide-header
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      size="md"
    >
      <BRow class="justify-center my-1 flex flex-column">
        <BCol class="d-flex justify-content-center">
          <div class="text-black font-semibold text-xl">
            Verifikasi PIN
          </div>
        </BCol>
        <BCol class="d-flex justify-content-center mt-1">
          <div class="text-black text-md">
            Mohon verifikasi identitias kamu dengan memasukkan PIN
          </div>
        </BCol>
        <BCol class="d-flex justify-content-center mt-2 mb-1">
          <CodeInput
            :style="isVisibility ?'-webkit-text-security: disc' : '-webkit-text-security: none'"
            :loading="false"
            class="input"
            :type="visibilityPin"
            :field-width="40"
            @change="handleChange"
          />
        </BCol>
        <BCol class="d-flex justify-content-center">
          <BButton
            variant="flat-primary"
            class="btn-icon"
            @click="visiblePin"
          >
            {{ isVisibility ? 'Tampilkan' : 'Sembunyikan' }}
          </BButton>
        </BCol>
        <BCol
          class="d-flex my-1 justify-content-center font-bold text-primary cursor-pointer"
          @click="$router.push('/setting-kompship/pin')"
        >
          Lupa PIN?
        </BCol>
        <BCol class="d-flex justify-content-center text-primary">
          <small class="text-danger text-center">{{ errorMessage }}</small>
        </BCol>
        <BCol class="d-flex justify-content-center mt-1 pb-2 gap-4">
          <BButton
            variant="outline-primary"
            @click="handleClose()"
          >
            Kembali
          </BButton>
          <BButton
            :variant="pin.length < 6 || loading.reveal ? 'secondary' : 'primary'"
            :disabled="pin.length < 6 || loading.reveal"
            type="submit"
            @click.prevent="revealCard"
          >
            <BSpinner
              v-if="loading.reveal"
              variant="light"
              small
            />
            Submit
          </BButton>
        </BCol>
      </BRow>
    </BModal>

    <ModalTopup
      :card-data="cards[0]"
      :get-mutation="getMutation"
      :get-cards="getCards"
      :balance-data="balanceData"
      :balance-detail="balanceDetail"
      :modal-type="modalType"
    />

    <ModalTransfer
      :info-balance="balanceDetail"
    />

    <ModalVerificationKyc
      :id-modal="idModal"
      :title="'Kamu perlu verifikasi KTP'"
      :desc="'Yuk ajukan verifikasi KTP dan Selfie dengan KTP sekarang juga untuk memvalidasi keaslian akunmu dan melihat data kartumu!'"
      :source="'pending'"
      :submit="routeVerification"
    />

    <ModalChangeCardName
      :card="cards[0]"
      :get-cards="getCards"
    />
  </BCard>
</template>

<script>
import { IDR } from '@/libs/currency'
import { newAxiosIns, komshipAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import FlatPickr from 'vue-flatpickr-component'
import VSelect from 'vue-select'
import CodeInput from 'vue-verification-code-input'
import {
  columnConfigDetail, optionsTransactionType,
} from './config'
import '@/@core/scss/vue/libs/vue-flatpicker.scss'
import 'vue-select/dist/vue-select.css'
import {
  Mutation, ModalTopup, ModalVerificationKyc, CCDetail, ModalTransfer, ModalChangeCardName,
} from './components'

export default {
  components: {
    Mutation,
    VSelect,
    FlatPickr,
    ModalTopup,
    CodeInput,
    ModalVerificationKyc,
    CCDetail,
    ModalTransfer,
    ModalChangeCardName,
  },
  data() {
    return {
      IDR,
      loading: {
        mutation: false,
        reveal: false,
        card: false,
        file: false,
        informationBalance: false,
      },
      listMutation: [],
      cards: [],
      reveal: {
        cardNumber: null,
        cvv: null,
        expDate: null,
      },
      showReveal: false,
      isVisibility: true,
      visibilityPin: 'number',
      errorMessage: '',
      pin: '',
      cardId: this.$route.params.id,
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      transactionType: '',
      limit: 10,
      offset: 0,
      timerCount: 60,
      fields: columnConfigDetail,
      optionsTransactionType,
      idPartner: this.$store.state.auth.userData.id,
      balanceData: null,
      lastData: false,
      label: null,
      labelOriginal: null,
      modalType: null,
      balanceDetail: {},
      idModal: 'modal-requested-kyc',
      action: [
        {
          id: 1,
          label: 'Top Up',
          icon: 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/card-send.svg',
          onClick: () => {
            this.handleTopup('topup')
          },
        },
        {
          id: 2,
          label: 'Tarik Saldo',
          icon: 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/card-receive.svg',
          onClick: () => {
            this.checkReveal('withdrawal')
            this.checkBalance()
          },
        },
        {
          id: 3,
          label: 'Transfer Saldo',
          icon: 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/convert-card.svg',
          onClick: () => {
            this.checkBalance()
            this.$bvModal.show('modal-transfer')
          },
        },
        {
          id: 4,
          label: 'Ubah Nama Kartu',
          icon: 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/card-edit.svg',
          onClick: () => {
            this.$bvModal.show('modal-change-card-name')
          },
        },
      ],
    }
  },
  computed: {
    setTransactionType() {
      return type => { this.transactionType = type }
    },
    setStartDate() {
      return date => { this.startDate = date }
    },
    setEndDate() {
      return date => { this.endDate = date }
    },
    statusClass() {
      return status => {
        if (status === 'success') {
          return 'text-[#34A770]'
        }
        if (status === 'failed') {
          return 'text-[#E31A1A]'
        }
        if (status === 'pending') {
          return 'text-[#828282]'
        }
        return ''
      }
    },
    nominalClass() {
      return type => {
        if (type === 'credit') {
          return 'text-[#34A770]'
        }
        if (type === 'debit') {
          return 'text-[#E31A1A]'
        }
        if (type === 'cashback') {
          return 'text-[#CC9212]'
        }
        return ''
      }
    },
    resetFilter() {
      return () => {
        this.startDate = moment().format('YYYY-MM-DD')
        this.endDate = moment().format('YYYY-MM-DD')
        this.transactionType = ''
        this.getMutation(this.$route.params.id)
        this.offset = 0
      }
    },
    handleTopup() {
      return value => {
        this.$bvModal.show('modal-topup')
        if (value !== 'withdrawal') {
          this.getBalance()
        }
        this.modalType = value
      }
    },
    visiblePin() {
      return () => {
        this.isVisibility = !this.isVisibility
        this.visibilityPin = this.isVisibility ? 'number' : 'text'
      }
    },
    handleChange() {
      return value => {
        this.pin = value
      }
    },
    handleClose() {
      return () => {
        this.$bvModal.hide('modal-confirm-pin')
        this.errorMessage = ''
        this.pin = ''
      }
    },
  },
  created() {
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.getElementById('scroll').offsetHeight && !this.loading.mutation) {
        this.getNextDataMutation()
      }
    }
  },
  mounted() {
    this.getCards()
    this.getMutation()
  },
  methods: {
    async getCards() {
      this.loading.card = true
      const url = `/komcards/api/v1/card/list?card_id=${this.cardId}`
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.cards = data
          this.loading.card = false
        })
        .catch(() => {
          this.loading.card = false
          this.$toast_error({ message: 'Gagal memuat data kartu, silahkan coba lagi' })
        })
    },
    async getMutation() {
      this.loading.mutation = true
      this.offset = 0
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        transaction_type: this.transactionType,
        offset: this.offset,
        limit: this.limit,
        card_id: this.cardId,
      }
      const url = '/komcards/api/v1/card/mutation'
      await newAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data
          this.listMutation = data
          this.loading.mutation = false

          this.offset = data.length
          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(() => {
          this.loading.mutation = false
          this.$toast_error({ message: 'Gagal memuat data mutasi, silahkan coba lagi' })
        })
    },
    getNextDataMutation() {
      if (!this.lastData) {
        this.loading.mutation = true
        const params = {
          start_date: this.startDate,
          end_date: this.endDate,
          transaction_type: this.transactionType,
          offset: this.offset,
          limit: this.limit,
          card_id: this.cardId,
        }
        const url = '/komcards/api/v1/card/mutation'
        newAxiosIns.get(url, { params })
          .then(res => {
            const { data } = res.data
            this.loading.mutation = false
            this.listMutation.push(...data)
            this.offset += data.length
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(() => {
            this.loading.mutation = false
            this.$toast_error({ message: 'Gagal memuat data mutasi, silahkan coba lagi' })
          })
      }
    },
    async revealCard() {
      this.loading.reveal = true
      const url = `/komcards/api/v1/card/${this.cardId}/show?pin=${this.pin}`
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.loading.reveal = false
          this.reveal = data
          this.showReveal = true
          this.pin = ''
          this.errorMessage = ''
          this.$bvModal.hide('modal-confirm-pin')
          this.decryptedCard(data.encrypted_data)
          this.countDownTimer()
        })
        .catch(err => {
          const { message } = err.response.data
          this.loading.reveal = false

          if (message === 'wrong pin numbers') {
            this.errorMessage = 'PIN yang anda masukan salah'
          } else {
            this.errorMessage = message
          }
        })
    },
    async getBalance() {
      const url = `/v1/dashboard/partner/balanceSummary?partner_id=${this.idPartner}`
      await komshipAxiosIns.get(url)
        .then(res => {
          const { data: { balance } } = res.data
          this.balanceData = balance
          if (this.showModalTopup === true) {
            this.$bvModal.show('modal-topup')
          }
        })
        .catch(() => {
          this.$toast_error({ message: 'Gagal memuat data balance, silahkan coba lagi' })
        })
    },
    async checkBalance() {
      this.loading.informationBalance = true
      const url = `/komcards/api/v1/card/${this.cardId}/info-balance`
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.loading.informationBalance = false
          this.balanceDetail = data
        })
        .catch(() => {
          this.loading.informationBalance = false
          this.$toast_error({ message: 'Gagal memuat data balance, silahkan coba lagi' })
        })
    },
    async checkReveal(type) {
      this.loading.file = true
      const url = '/auth/api/v1/partner/request-verification-ktp/latest-history'
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.loading.file = false
          this.kyc = data
          if (data.status === 'approved') {
            return type === 'reveal' ? this.$bvModal.show('modal-confirm-pin') : this.handleTopup('withdrawal')
          }
          if (data.status === 'requested') {
            return this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Fitur Ini Belum Bisa Digunakan',
                icon: 'AlertCircleIcon',
                text: 'Kamu sudah mengajukan Verifikasi KTP agar dapat menggunakan fitur ini. Silahkan tunggu konfirmasi lebih lanjut selama 1x24 jam',
                variant: 'danger',
              },
            }, { timeout: 2000 })
          }
          if (data.status === 'not requested') {
            return this.$bvModal.show(this.idModal)
          }
          return this.$router.push('/verification-komcard')
        })
        .catch(() => {
          this.loading.file = false
        })
    },
    countDownTimer() {
      const timer = setInterval(() => {
        if (this.timerCount > 0) {
          this.timerCount -= 1
        } else {
          this.showReveal = false
          this.timerCount = 60
          clearInterval(timer)
        }
      }, 1000)
    },
    copyCardNumber() {
      if (this.showReveal) {
        navigator.clipboard.writeText(this.reveal.cardNumber)
        this.$toast_success({ message: 'Nomor kartu berhasil disalin' })
      }
    },
    routeVerification() {
      this.$router.push('/verification-komcard')
    },
    decryptedCard(encrypted) {
      const decode = atob(encrypted)
      const numberArray = decode.split('%%').map(Number)
      const resultArray = numberArray.map((value, index) => {
        const result = value / (3 * (index + 1))
        if (index === 2) {
          return result.toString()
        } if ([0, 1, 3, 4, 5].includes(index)) {
          return (result < 1000) ? result.toString().padStart(4, '0') : result.toFixed(0)
        }
        return result.toFixed(0)
      })
      const cardNumber = resultArray.slice(0, 2).concat(resultArray.slice(4, 6)).join('').match(/.{1,4}/g)
        .join(' ')
      const cvv = resultArray[2].padStart(3, 0)
      const expDate = `${resultArray[3].substring(0, 2)}/${resultArray[3].substring(2)}`

      this.reveal.cardNumber = cardNumber
      this.reveal.cvv = cvv
      this.reveal.expDate = expDate
    },
  },
}
</script>
